export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    customRender: (_, __, index) => index + 1,
  },
  {
    title: "产品名称",
    dataIndex: "product_name",
    customRender: (_, item) => item.goods_item.name,
  },
  {
    title: "产品编号",
    dataIndex: "product_number",
    customRender: (_, item) => item.goods_item.number,
  },
  {
    title: "产品条码",
    dataIndex: "product_barcode",
    customRender: (_, item) => item.goods_item.barcode,
  },
  {
    title: "产品规格",
    dataIndex: "product_spec",
    customRender: (_, item) => item.goods_item.spec,
  },
  {
    title: "入库数量",
    dataIndex: "stock_in_quantity",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];
